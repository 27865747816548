// material-ui
import { Container, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import { Call } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from 'react-router-dom';

// styles
const FooterWrapper = styled('div')(({ theme }) => ({
    padding: '35px 0',
    color: '#fff',
    background: theme.palette.primary.main
    // [theme.breakpoints.down('md')]: {
    //     textAlign: 'center'
    // }
}));

// const FooterLink = styled(Link)({
//     color: '#fff',
//     display: 'flex',
//     alignItems: 'center',
//     textDecoration: 'none !important',
//     opacity: '0.8',
//     '& svg': {
//         fontsize: '1.125rem',
//         marginRight: 4
//     },
//     '&:hover': {
//         opacity: '1'
//     }
// });

const FooterSubWrapper = styled('div')(({ theme }) => ({
    padding: '20px 0',
    color: '#fff',
    background: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <FooterWrapper>
                <Container>
                    <Grid container alignItems="center" spacing={gridSpacing}>
                        <Grid item xs={12} sm={4}>
                            <Link
                                sx={{
                                    textDecoration: 'none',
                                    ':hover': {
                                        cursor: 'pointer'
                                    },
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                <img src="logo192.png" alt="eco petrochemical" width="100" style={{ margin: 'inherit' }} />
                                <Typography color="white" variant="h2">
                                    Eco Petroleum
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography color="white" variant="subtitle1" sx={{ mb: { xs: 1, md: 2 }, mt: { xs: 1.5, md: 0 } }}>
                                Contact Us
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <Call fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                1844-ECO-0008
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <LocationOnIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                Suite 400, 2 County Court Blvd, Brampton L3W 5W1, Ontario
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <EmailIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                info@ecopetroleum.ca
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography color="white" variant="subtitle1" sx={{ mb: { xs: 1, md: 2 }, mt: { xs: 1.5, md: 0 } }}>
                                Social Media
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <FacebookIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                Facebook
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <TwitterIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                Twitter
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 0.5,
                                    justifyContent: { xs: 'left', md: 'unset' }
                                }}
                                color="white"
                            >
                                <InstagramIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
                                Instagram
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </FooterWrapper>
            <FooterSubWrapper>
                <Container>
                    <Typography variant="subtitle1" component="div" color="inherit" align="center">
                        &#169; All Right Reserved - Eco Petroleum 2022
                    </Typography>
                </Container>
            </FooterSubWrapper>
        </>
    );
};

export default FooterPage;
