import { apiKeys } from 'config';

export const onEmailSubscribe = async (email) => {
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': apiKeys.emailApiKey
        },
        body: JSON.stringify({
            email,
            attributes: { FNAME: '', LNAME: '' },
            emailBlacklisted: false,
            smsBlacklisted: false,
            listIds: [36],
            updateEnabled: false
        })
    };

    await fetch('https://api.sendinblue.com/v3/contacts', options);
};

export const getNews = (page = 1) => {
    const url = `https://newsdata.io/api/1/news?apikey=${apiKeys.newsApiKey}&country=ca,us&language=en&category=business,environment,health,politics,top&page=${page}`;

    const req = new Request(url);

    return fetch(req);
};

export const setContactUsFormInfo = (info) => {
    console.log(info);
};
